import React, {useEffect} from 'react';

import styled from 'styled-components';
import media from '../styles/media';
import colors from '../styles/colors';
import text from '../styles/text';
import gsap from 'gsap'
import PLbyVasionTop from 'images/Nav/PLbyVasionTop.svg'
import { ReactComponent as Arrow } from 'images/Nav/DownArrow.svg'
import ContentWidth from './ContentWidth'


const PrimeNav = ({urlStatus, slug}) => {
  const nonHomeSlug = slug === '' ? '' : slug
  useEffect(() => {
    gsap.set("#fd-1", {alpha: 0});
    gsap.set(".Inner-1", {alpha: 0});
    gsap.set("#fd-2", {alpha: 0});
    gsap.set(".Inner-2", {alpha: 0});
    gsap.set("#fd-3", {alpha: 0});
    gsap.set(".Inner-3", {alpha: 0});
    gsap.set("#fd-4", {alpha: 0});
    gsap.set(".Inner-4", {alpha: 0});

const t1 = gsap.timeline({paused: true});

t1.to("#fd-1", {duration: .01, display: 'flex'})
.to("#fd-1", {duration: .5, alpha: 1, width: 'auto', height: 'auto'})
.to(".Inner-1", {duration: .5, alpha: 1 })

const t2 = gsap.timeline({paused: true});
t2.to("#fd-2", {duration: .01, display: 'flex'})
.to("#fd-2", {duration: .5, alpha: 1, width: 'auto', height: 'auto'})
.to(".Inner-2", {alpha: 1 })

const t3 = gsap.timeline({paused: true});
t3.to("#fd-3", {duration: .01, display: 'flex'})
.to("#fd-3", {duration: .5, alpha: 1, width: 'auto', height: 'auto'})
.to(".Inner-3", {alpha: 1 })

const t4 = gsap.timeline({paused: true});
t4.to("#fd-4", {duration: .01, display: 'flex'})
.to("#fd-4", {duration: .5, alpha: 1, width: 'auto', height: 'auto'})
.to(".Inner-4", {alpha: 1 })

document.getElementById("tab-1").addEventListener("mouseenter", animate1); 
document.getElementById("tab-2").addEventListener("mouseenter", animate2);
document.getElementById("tab-3").addEventListener("mouseenter", animate3);
document.getElementById("tab-4").addEventListener("mouseenter", animate4);
document.getElementById("fd-1").addEventListener("mouseleave", reverse); 
document.getElementById("fd-2").addEventListener("mouseleave", reverse);
document.getElementById("fd-3").addEventListener("mouseleave", reverse);
document.getElementById("fd-4").addEventListener("mouseleave", reverse);
document.getElementById("PrimeTabsContainer").addEventListener("mouseleave", reverse);

async function animate1() {

  await Promise.all([
    t2.timeScale(2).reverse(),
    t3.timeScale(2).reverse(),
    t4.timeScale(2).reverse(),
  ]);
  
  t1.timeScale(1).play();
}

async function animate2() {
  
  await Promise.all([
    t1.timeScale(2).reverse(),
    t3.timeScale(2).reverse(),
    t4.timeScale(2).reverse()
  ]);

  t2.timeScale(1).play();
}

async function animate3() {
  
  await Promise.all([
    t1.timeScale(2).reverse(),
    t2.timeScale(2).reverse(),
    t4.timeScale(2).reverse(),
  ]);
  
  t3.timeScale(1).play();
}
async function animate4() {
  
  await Promise.all([
    t1.timeScale(2).reverse(),
    t2.timeScale(2).reverse(),
    t3.timeScale(2).reverse(),
  ]);
  
  t4.timeScale(1).play();
}

async function reverse() {

  await Promise.all([
    t1.timeScale(2).reverse(),
    t2.timeScale(2).reverse(),
    t3.timeScale(2).reverse(),
    t4.timeScale(2).reverse(),
  ]);
}

  }, [])

  const allTabs = [
    {
      name: 'company',
      tier1: [
        {
          name: 'About Us',
          link: 'https://vasion.com/company/'
        },
        {
          name: 'Careers',
          link: 'https://vasion.com/careers/'
        },
        {
          name: 'Contact Us',
          link: '/contact-us'
        },
        {
          name:'Giving',
          link:'https://vasion.com/vasion-gives-back'
        }, 
        {
          name:'Our Customers',
          link:'https://printerlogic.com/customers/'
        }, 
        {
          name: 'Visit',
          link:'https://vasion.com/vasion-executive-briefing-center'
        }
      ]
    },
    {
      name: 'partners',
      tier1: [
        {
          name: 'Partner Overview',
          link: 'https://vasion.com/partner-overview/'
        },
        {
          name: 'MSP Partners',
          link: 'https://vasion.com/msp-partner/'
        },
        {
          name: 'Sales Partners',
          link: 'https://vasion.com/sales-partners/'
        },
        {
          name: 'Ecosystem Partners',
          link: 'https://vasion.com/ecosystem-partners/'
        },
        {
          name: 'Become a Partner',
          link: 'https://partners.vasion.com/prm/English/s/applicant'
        },
        {
          name: 'Partner Login',
          link: 'https://partners.vasion.com'
        },
      ]
    },
    {
      name: 'support',
      tier1: [
        {
          name: 'Supported Printers',
          link: '/cpa'
        },
        {
          name: 'Support',
          link: 'https://kb.printerlogic.com/s/contact-printerlogic-support'
        },
      ]
    },
    {
      name:'language',
      tier1: [
        {
          name:'English',
          link: `https://printerlogic.com/${nonHomeSlug}`
        },
        {
          name: 'German',
          link:`https://de.printerlogic.com/${nonHomeSlug}`
        },
        {
          name:'French',
          link:`https://fr.printerlogic.com/${nonHomeSlug}`
        }
      ]
    }
  ]

  const mainTabs = allTabs.map((tab, index, arr) =>   
    <PrimaryContainer key={tab.name} id={`tab-${index+1}`}>
      <PrimaryText className='nav'>{tab.name} <DownArrow/></PrimaryText>
        <FirstDrop id={`fd-${index+1}`} urlStatus={urlStatus}>
            {tab.tier1.map((content, i, arr) => 
            <Link key={`FDlink-${content.name
            }`} href={content.link}>
              <Tier1Div className={`Inner-${index+1}`} indexpos={i === arr.length - 1}>
                <Tier1Content className='nav'>
                  {content.name}
                </Tier1Content>
              </Tier1Div>
            </Link>
            )}
        </FirstDrop>
    </PrimaryContainer>
  )

  return (
      <Wrapper urlStatus={urlStatus}>
        <ContentWidth nav>
            <Link href='https://vasion.com'>
              <Logo src={PLbyVasionTop} alt="vasion solution"/>
            </Link>
          <TabsContainer id='PrimeTabsContainer'>
            {mainTabs}
          </TabsContainer>
        </ContentWidth>
      </Wrapper>
    )
  }

export default PrimeNav

const DownArrow = styled(Arrow)`
  width: 0.417vw;
  height: 0.278vw;

  path {
    fill: ${colors.grey75};
  }

  ${media.fullWidth} {
    width: 6px;
    height: 4px;
  }
`
const Logo = styled.img`


  ${media.fullWidth} {

  }
`
const Link = styled.a`
  position: relative;
  display: flex;
  flex-direction: ${props => props.firstIndex ? 'row' : 'column'};
  color: ${colors.black};
`
const Tier1Content = styled.p`
  ${text.m2};
  width: max-content;
`
const Tier1Div = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: auto;
  min-width: ${props => props.firstIndex ? '260px' : 'unset'};
  height: 5.278vw;
  padding: ${props => props.indexpos ? '1.944vw 1.944vw 2.222vw 1.944vw' : '1.944vw'};
  border-radius: ${props => props.indexpos ? '0 0 0.556vw 0.556vw' : '0'};

  ${media.hover} {
    &:hover {
      background-color: ${colors.white};
      color: ${colors.primaryOrange};
    }
  }

  ${media.fullWidth} {
    height: 76px;
    padding: ${props => props.indexpos ? '28px 28px 32px 28px' : '28px'} ;
    border-radius: ${props => props.indexpos ? '0 0 8px 8px' : '0'};
  }
`
const FirstDrop = styled.div`
  position: absolute;
  display: none;
  overflow: hidden;
  flex-direction: ${props => props.firstIndex ? 'row' : 'column'};
  background-color: ${props => props.urlStatus ? colors.grey50 : colors.grey25};
  opacity: 0;
  width: 0;
  height: 0;
  top: 2.75vw;
  border-radius: 0 0 0.972vw 0.972vw;
  filter: drop-shadow(0vw 0.278vw 0.556vw rgba(0, 0, 0, 0.25));

  ${media.fullWidth} {
    top: 38px;
    background-color: ${props => props.urlStatus ? colors.grey50 : colors.grey25};
    border-radius: 0 0 14px 14px;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
  }
`

const PrimaryText = styled.p`
  ${text.m1};
  text-transform: uppercase;
  color: ${colors.grey75};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.417vw;

  ${media.fullWidth} {
    gap: 6px;
  }
`
const PrimaryContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: auto;
  height: 2.222vw;
  padding: 0 1.944vw;

  ${media.hover} {
    &:hover {
      cursor: pointer;

      ${PrimaryText} {
        color: ${colors.grey500};
        path {
          fill: ${colors.grey500};
        }
      }
    }
  }

  ${media.fullWidth} {
    height: 32px;
    padding: 0 28px;
  }
`
const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  margin-left: auto;
`
const Wrapper = styled.div`
  position: relative;
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${colors.grey700};
  width: 100%;
  height: 3.125vw;
  margin-right: 1.875vw;
  padding: 1.25vw 1.667vw;

  ${media.fullWidth} {
    height: 45px;
    margin-right: 27px;
    padding: 18px 24px;
  }
  
  ${media.tablet} {
    overflow: hidden;
  }
  
  ${media.mobile} {
    overflow: hidden;
  }
`