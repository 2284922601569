import React from "react"
import Logo from "../images/Footer/PL-Footer.png"
import logoPlRev from 'images/Footer/logo_PL_rev.svg';
import ContentWidth from "./ContentWidth"
import styled from "styled-components"
import colors from "../styles/colors"
import SocialLinks from "./SocialLinks"
import FooterMenu from "./FooterMenu"
import media from "../styles/media"


const Footer = () => {
  return (
    <Wrapper>
      <ContentWidth>
        <FlexRowContainer>
        <FlexColContainer>
          <FooterLogo alt="logo" src={logoPlRev}/> 
          <SocialLinks/>
        </FlexColContainer>
        <FooterMenu/>
        </FlexRowContainer>
        <LegalContainer>© 2024 PrinterLogic. All Rights Reserved | <Link href="https://vasion.com/privacy-policy">Privacy Policy</Link> | <Link href="https://vasion.com/imprint">Imprint</Link> | <Link href="https://vasion.com/cookie-information">Cookies</Link> | <Link href="https://vasion.com/legal">Legal</Link> </LegalContainer>
      </ContentWidth>
    </Wrapper>
  )
}
const Wrapper = styled.footer`
  background-color: #191D1E;
  color: white; 
  height: auto;
  position: relative;
  bottom:0;
  display: flex;

  ${media.tablet} {
    padding: 1.953vw;
  }
  
  /* overflow: hidden; */
`
const Link = styled.a`
  font-family: Archivo;
  font-size: 1.111vw;
  line-height: 1.667vw;
  margin-bottom: 2.778vw;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: ${colors.grey300};

  ${media.fullWidth}{ 
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 40px;
  }
  ${media.tablet}{
    font-size: 1.563vw;
    line-height: 2.344vw;
    margin-bottom: 3.516vw;
  }
  ${media.mobile}{
    font-size: 4.267vw;
    line-height: 6.4vw;
    margin: 5.333vw 6.933vw 8.533vw;
  }
  ${media.hover}{
    :hover {
      text-decoration: underline;
    }
  }
`
const FooterLogo = styled.img`
width: 10.278vw;

${media.fullWidth}{
  width: 148px;
}
${media.tablet}{
  width: 14.453vw;
}
${media.mobile}{
  width: 39.467vw;
}
`
const FlexColContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 11.111vw;

  ${media.fullWidth}{
    gap: 160px;
  }
  ${media.tablet}{
    width: 18.453vw;
    gap: 15.625vw;
  }
  ${media.mobile}{
    flex-direction: row;
    gap: 5.333vw;
    margin: 0 2.667vw;
    justify-content: center;
    align-items: flex-start;
  }
`
const FlexRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 44px;
  margin:5.556vw auto 2.778vw;
  
  ${media.fullWidth}{
    gap: 44px;
    margin:80px auto 40px;
  }
  ${media.tablet}{
    gap: 4.297vw;
    margin:7.813vw auto 3.906vw;
  }
  ${media.mobile}{
    flex-direction: column;
    margin:10.667vw auto 0vw;
  }
`
const LegalContainer = styled.div`
font-family: Archivo;
font-size: 1.111vw;
line-height: 1.667vw;
margin-bottom: 2.778vw;
font-weight: 400;
letter-spacing: 0em;
text-align: left;
color: ${colors.grey300};

  ${media.fullWidth}{ 
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 40px;
  }
  ${media.tablet}{
    font-size: 1.563vw;
    line-height: 2.344vw;
    margin-bottom: 3.516vw;
  }
  ${media.mobile}{
    font-size: 4.267vw;
    line-height: 6.4vw;
    margin: 5.333vw 6.933vw 8.533vw;

  }
`



export default Footer;