import React, { useEffect } from "react"

import styled from "styled-components"
import media from "../styles/media"
import colors from "../styles/colors"
import text from "../styles/text"
import gsap from "gsap"
import logoPL from "images/Nav/logo_PL.svg"
import { ReactComponent as Arrow } from "images/Nav/DownArrow.svg"
import { ReactComponent as Arrow2 } from "images/Nav/SideArrow.svg"
import ContentWidth from "./ContentWidth"
import GlobalButton from "components/buttons/GlobalButton"
// import getMedia from 'utils/getMedia.js'

const SecondaryNav = ({ urlStatus }) => {
  useEffect(() => {
    gsap.set("#fd2-1", { alpha: 0 })
    gsap.set(".Inner2-1", { alpha: 0 })
    gsap.set(".Side2-1", { alpha: 0 })
    gsap.set(".Side2-2", { alpha: 0 })
    gsap.set(".Side2-3", { alpha: 0 })
    gsap.set(".Side2-4", { alpha: 0 })
    gsap.set(".Side2-5", { alpha: 0 })
    gsap.set("#fd2-2", { alpha: 0 })
    gsap.set(".Inner2-2", { alpha: 0 })
    gsap.set("#fd2-3", { alpha: 0 })
    gsap.set(".Inner2-3", { alpha: 0 })
    gsap.set("#fd2-4", { alpha: 0 })
    gsap.set(".Inner2-4", { alpha: 0 })

    const t1 = gsap.timeline({ paused: true })
    t1.to("#fd2-1", { duration: 0.01, display: "flex" })
      .to("#fd2-1", { duration: 0.5, alpha: 1, width: "auto", height: "auto" })
      .to(".Inner2-1", { duration: 0.5, alpha: 1 })

    const t2 = gsap.timeline({ paused: true })
    t2.to("#fd2-2", { duration: 0.01, display: "flex" })
      .to("#fd2-2", { duration: 0.5, alpha: 1, width: "auto", height: "auto" })
      .to(".Inner2-2", { alpha: 1 })

    const t3 = gsap.timeline({ paused: true })
    t3.to("#fd2-3", { duration: 0.01, display: "flex" })
      .to("#fd2-3", { duration: 0.5, alpha: 1, width: "auto", height: "auto" })
      .to(".Inner2-3", { alpha: 1 })

    const t4 = gsap.timeline({ paused: true })
    t4.to("#fd2-4", { duration: 0.01, display: "flex" })
      .to("#fd2-4", { duration: 0.5, alpha: 1, width: "auto", height: "auto" })
      .to(".Inner2-4", { alpha: 1 })

    const s1 = gsap.timeline({ paused: true })
    s1.to(".Side2-1", { duration: 0.5, alpha: 1 })

    const s2 = gsap.timeline({ paused: true })
    s2.to(".Side2-2", { duration: 0.5, alpha: 1 })

    const s3 = gsap.timeline({ paused: true })
    s3.to(".Side2-3", { duration: 0.5, alpha: 1 })

    const s4 = gsap.timeline({ paused: true })
    s4.to(".Side2-4", { duration: 0.5, alpha: 1 })

    const s5 = gsap.timeline({ paused: true })
    s5.to(".Side2-5", { duration: 0.5, alpha: 1 })

    document.getElementById("tab2-1").addEventListener("mouseenter", animate1)
    document.getElementById("tab2-2").addEventListener("mouseenter", animate2)
    document.getElementById("tab2-3").addEventListener("mouseenter", animate3)
    document.getElementById("tab2-4").addEventListener("mouseenter", animate4)
    document.getElementById("fd2-1").addEventListener("mouseleave", reverse)
    document.getElementById("fd2-2").addEventListener("mouseleave", reverse)
    document.getElementById("fd2-3").addEventListener("mouseleave", reverse)
    document.getElementById("fd2-4").addEventListener("mouseleave", reverse)

    document
      .getElementById("PrimeTabsContainer2")
      .addEventListener("mouseleave", reverse)
    document
      .getElementById("SideHover2-1")
      .addEventListener("mouseenter", side1)
    document
      .getElementById("SideHover2-2")
      .addEventListener("mouseenter", side2)
    document
      .getElementById("SideHover2-3")
      .addEventListener("mouseenter", side3)
    document
      .getElementById("SideHover2-4")
      .addEventListener("mouseenter", side4)
    document
      .getElementById("SideHover2-5")
      .addEventListener("mouseenter", side5)

    async function animate1() {
      await Promise.all([
        t2.timeScale(2).reverse(),
        t3.timeScale(2).reverse(),
        t4.timeScale(2).reverse(),
      ])

      t1.timeScale(1).play()
    }

    async function animate2() {
      await Promise.all([
        t1.timeScale(2).reverse(),
        t3.timeScale(2).reverse(),
        t4.timeScale(2).reverse(),
      ])

      t2.timeScale(1).play()
    }

    async function animate3() {
      await Promise.all([
        t1.timeScale(2).reverse(),
        t2.timeScale(2).reverse(),
        t4.timeScale(2).reverse(),
      ])

      t3.timeScale(1).play()
    }

    async function animate4() {
      await Promise.all([
        t1.timeScale(2).reverse(),
        t2.timeScale(2).reverse(),
        t3.timeScale(2).reverse(),
      ])

      t4.timeScale(1).play()
    }

    async function side1() {
      await Promise.all([
        s2.timeScale(2).reverse(),
        s3.timeScale(2).reverse(),
        s4.timeScale(2).reverse(),
        s5.timeScale(2).reverse(),
      ])

      s1.timeScale(1).play()
    }

    async function side2() {
      await Promise.all([
        s1.timeScale(2).reverse(),
        s3.timeScale(2).reverse(),
        s4.timeScale(2).reverse(),
        s5.timeScale(2).reverse(),
      ])

      s2.timeScale(1).play()
    }

    async function side3() {
      await Promise.all([
        s1.timeScale(2).reverse(),
        s2.timeScale(2).reverse(),
        s4.timeScale(2).reverse(),
        s5.timeScale(2).reverse(),
      ])

      s3.timeScale(1).play()
    }

    async function side4() {
      await Promise.all([
        s1.timeScale(2).reverse(),
        s2.timeScale(2).reverse(),
        s3.timeScale(2).reverse(),
        s5.timeScale(2).reverse(),
      ])

      s4.timeScale(1).play()
    }

    async function side5() {
      await Promise.all([
        s1.timeScale(2).reverse(),
        s2.timeScale(2).reverse(),
        s3.timeScale(2).reverse(),
        s4.timeScale(2).reverse(),
      ])

      s5.timeScale(1).play()
    }

    async function reverse() {
      await Promise.all([
        t1.timeScale(2).reverse(),
        t2.timeScale(2).reverse(),
        t3.timeScale(2).reverse(),
        t4.timeScale(2).reverse(),
        s1.timeScale(2).reverse(),
        s2.timeScale(2).reverse(),
        s3.timeScale(2).reverse(),
        s4.timeScale(2).reverse(),
        s5.timeScale(2).reverse(),
      ])
    }
  }, [])

  const allTabs = [
    {
      name: "Why PrinterLogic",
      link: "/why-printerlogic/",
    },
    {
      name: "Product",
      tier1: [
        {
          name: "Serverless Printing",
          link: "/",
          sideContent: {
            links: [
              {
                name: "Serverless Printing Infrastructure",
                url: "/serverless-printing-infrastructure/",
              },
              {
                name: "Mobile Print",
                url: "/mobile-printing-information/",
              },
              {
                name: "Self-Service Printer Installation ",
                url: "/self-service-printer-installation-portal/",
              },
              //Automated Deployment Will go here
              /* ---------------------------------------------*/
              {
                name: "Supported Printers",
                url: "/cpa/",
              },
              {
                name: "Reporting",
                url: "/advanced-reporting-information/",
              },
              //Web Print will go here
              /* ---------------------------------------------*/
            ],
          },
        },
        {
          name: "Security",
          link: "/",
          sideContent: {
            links: [
              {
                name: "Secure Release Printing",
                url: "/secure-release-printing-information/",
              },
              {
                name: "Off-Network Printing",
                url: "/off-network-printing/",
              },
            ],
          },
        },
        {
          name: "Output Automation",
          link: "/",
          sideContent: {
            links: [
              {
                name: "Simplified Scanning",
                url: "/simplified-scanning/",
              },
              {
                name: "Output Management",
                url: "/output-management",
              },
            ],
          },
        },
        {
          name: "Reporting & Control",
          link: "/",
          sideContent: {
            links: [
              {
                name: "Print Quota Management",
                url: "/print-quota-management/",
              },
              {
                name: "Advanced Reporting",
                url: "/advanced-reporting-information/",
              },
            ],
          },
        },
        {
          name: "Integrations",
          link: "/",
          sideContent: {
            links: [
              {
                name: "PrinterLogic for IGEL",
                url: "/igel-integration/",
              },
              {
                name: "PrinterLogic for IdP",
                url: "/idp/",
              },
              {
                name: "PrinterLogic for VDI",
                url: "/vdi-printing-information/",
              },
              {
                name: "PrinterLogic for Chromebook",
                url: "/chromebook-printing/",
              },
              {
                name: "PrinterLogic for EMR",
                url: "/emr-printing-information/",
              },
            ],
          },
        },
        {
          name: "Vasion Platform",
          link: "https://vasion.com/",
          sideContent: {
            links: [],
          },
        },
      ],
    },
    {
      name: "industry",
      tier1: [
        {
          name: "Government",
          link: "/government/",
        },
        {
          name: "Healthcare",
          link: "/healthcare/",
        },
        {
          name: "Education",
          link: "/education/",
        },
        {
          name: "Enterprise",
          link: "/enterprise-print-management/",
        },
        {
          name: "Manufacturing",
          link: "/manufacturing/",
        },
      ],
    },
    {
      name: "resources",
      tier1: [
        {
          name: "Blog",
          link: "/blog",
        },
        {
          name: "All Resources",
          link: "/resources",
        },
        {
          name: "Security Bulletins",
          link: "https://help.printerlogic.com/saas/1-Printerlogic/Release_Notes/Security_Bulletin.htm",
        },
      ],
    },
  ]

  const mainTabs = allTabs.map((tab, index, arr) => (
    <Link key={`primaryContainer-${index}`} href={tab.link}>
      <PrimaryContainer id={`tab2-${index + 1}`}>
        <PrimaryText className="nav">
          {tab.name} {tab.tier1 && <DownArrow />}
        </PrimaryText>
        {index === 1 ? (
          <FirstDrop id={`fd2-${index + 1}`} secondindex>
            <SecondIndexDiv>
              {tab.tier1.map((content, index) => (
                <Link key={`link-${index}`} href={content.link} secondindex>
                  <Tier1Div
                    id={`SideHover2-${index + 1}`}
                    indexpos={index + 1 === arr.length - 1}
                    className={`Inner2-2`}
                    secondindex
                  >
                    <Tier1Content className="nav">
                      {content.name}
                      <SideArrow />
                    </Tier1Content>
                  </Tier1Div>
                </Link>
              ))}
            </SecondIndexDiv>
            <SideDropOuter>
              {tab.tier1.map((content, index) => (
                <Link key={`sideLink-${index}`} href={content.link}>
                  <SideDrop className={`Side2-${index + 1}`}>
                    <SideLinks>
                      {content.sideContent.links.map(link => (
                        <Link key={`link-${link.url}`} href={link.url}>
                          <SideLink className="nav">{link.name}</SideLink>
                        </Link>
                      ))}
                    </SideLinks>
                  </SideDrop>
                </Link>
              ))}
            </SideDropOuter>
          </FirstDrop>
        ) : (
          <FirstDrop id={`fd2-${index + 1}`}>
            {tab.tier1 &&
              tab.tier1.map((content, i, arr) => (
                <Link key={`FDlink-${content.link}`} href={content.link}>
                  <Tier1Div
                    className={`Inner2-${index + 1}`}
                    indexpos={i === arr.length - 1}
                  >
                    <Tier1Content className="nav">{content.name}</Tier1Content>
                  </Tier1Div>
                </Link>
              ))}
          </FirstDrop>
        )}
      </PrimaryContainer>
    </Link>
  ))

  return (
    <Wrapper urlStatus={urlStatus}>
      <ContentWidth nav>
        <TabsContainer id="PrimeTabsContainer2">
          <Link href="/">
            <Logo src={logoPL} />
          </Link>
          {mainTabs}
        </TabsContainer>
        <GlobalButton demo nav>
          SCHEDULE A DEMO
        </GlobalButton>
      </ContentWidth>
    </Wrapper>
  )
}

export default SecondaryNav

const SideArrow = styled(Arrow2)`
  width: 0.694vw;
  height: 0.694vw;
  margin-left: 0.556vw;

  ${media.fullWidth} {
    width: 10px;
    height: 10px;
    margin-left: 8px;
  }
`
const DownArrow = styled(Arrow)`
  width: 0.417vw;
  height: 0.278vw;

  ${media.fullWidth} {
    width: 6px;
    height: 4px;
  }
`
const Logo = styled.img`
  position: relative;
  top: 0.5vw;
  width: 9.167vw;
  margin-right: 1.875vw;

  ${media.fullWidth} {
    top: 7px;
    width: 132px;
    margin-right: 27px;
  }
`
const SecondIndexDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`
const SideLink = styled.div`
  height: 3.125vw;
  padding: 0vw 0 0.833vw 2.222vw;
  color: ${colors.grey600};
  z-index: 1000;

  ${media.hover} {
    &:hover {
      color: ${colors.primaryPurple};
    }
  }

  ${media.fullWidth} {
    height: 45px;
    padding: 0px 0 12px 32px;
  }
`
const SideLinks = styled.p`
  ${text.m3};
  width: auto;

  ${media.fullWidth} {
    width: 245px;
  }
`
const SideDrop = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: ${colors.white};
  height: auto;
  width: 100%;
  gap: 0.694vw;
  padding: 1.944vw 1.944vw 0vw 1.944vw;
  border-radius: 0 0 0.556vw 0;

  ${media.fullWidth} {
    gap: 10px;
    padding: 28px;
    border-radius: 0 0 8px 0;
  }
`

const SideDropOuter = styled.div`
  position: relative;
  width: 25vw;
  height: auto;
  overflow: hidden;

  ${media.fullWidth} {
    width: 360px;
  }
`
const Link = styled.a`
  position: relative;
  display: flex;
  flex-direction: ${props => (props.secondindex ? "row" : "column")};
  color: ${colors.black};
`
const Tier1Content = styled.p`
  ${text.m3};
  width: max-content;
`
const Tier1Div = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: auto;
  min-width: ${props => (props.secondindex ? "18.056vw" : "unset")};
  background-color: ${colors.grey25};
  height: 5.278vw;
  padding: ${props =>
    props.indexpos ? "1.944vw 1.944vw 2.222vw 1.944vw" : "1.944vw"};
  border-radius: ${props => (props.indexpos ? "0 0 0.556vw 0.556vw" : "0")};

  ${media.hover} {
    &:hover {
      background-color: ${colors.white};
      color: ${colors.primaryPurple};
    }
  }

  ${media.fullWidth} {
    min-width: ${props => (props.secondindex ? "260px" : "unset")};
    height: 76px;
    padding: ${props => (props.indexpos ? "28px 28px 32px 28px" : "28px")};
    border-radius: ${props => (props.indexpos ? "0 0 8px 8px" : "0")};
  }
`
const FirstDrop = styled.div`
  position: absolute;
  display: none;
  overflow: hidden;
  flex-direction: ${props => (props.secondindex ? "row" : "column")};
  background-color: ${colors.white};
  width: 0;
  height: 0;
  top: 3.75vw;
  border-radius: 0 0 0.972vw 0.972vw;
  box-shadow: 0vw 0.347vw 0.556vw rgba(0, 0, 0, 0.15);

  ${media.fullWidth} {
    top: 54px;
    border-radius: 0 0 14px 14px;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.15);
  }
`

const PrimaryText = styled.p`
  ${text.m1};
  text-transform: uppercase;
  color: ${colors.black};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.417vw;

  ${media.fullWidth} {
    gap: 6px;
  }
`
const PrimaryContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: auto;
  height: 2.222vw;
  padding: 0 1.944vw;

  ${media.hover} {
    &:hover {
      cursor: pointer;

      ${PrimaryText} {
        color: ${colors.primaryPurple};
        path {
          fill: ${colors.primaryPurple};
        }
      }
    }
  }

  ${media.fullWidth} {
    height: 32px;
    padding: 0 28px;
  }
`
const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
`
const Wrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${colors.grey25};
  width: 100%;
  height: 5.556vw;
  /* margin-right: 1.875vw; */
  padding: 1.736vw 1.667vw;
  filter: drop-shadow(0vw 0.347vw 0.556vw rgba(0, 0, 0, 0.15));

  ${media.fullWidth} {
    height: 80px;
    /* margin-right: 27px; */
    padding: 25px 24px;
    filter: drop-shadow(0px 5px 8px rgba(0, 0, 0, 0.15));
  }

  ${media.tablet} {
    overflow: hidden;
  }

  ${media.mobile} {
    overflow: hidden;
  }
`
