import React, { useEffect, useRef, useState } from "react"

import gsap from "gsap"
import styled from "styled-components"
import media from "styles/media"
import colors from "styles/colors"
import text from "styles/text"
import logoPL from "images/Nav/logo_PL.svg"
import { ReactComponent as DownChevron } from "/src/images/ChevronDown.svg"
import getMedia from "../../utils/getMedia"
import { vwToPx } from "../../utils/functions"
import GlobalButton from "components/buttons/GlobalButton"

const SecondaryNavResp = () => {
  const hamburgerRef = useRef(true)
  const subTabTextRef2 = useRef(true)
  const [navWrapper, setNavWrapper] = useState(null)

  useEffect(() => {
    document.querySelector("#hamburger2").addEventListener("click", expandMenu)

    const tabs = gsap.utils.toArray(".tabs2")

    tabs.forEach((tab, index) => {
      const tl = gsap.timeline()
      tab.animation = tl
        .to(`#tab2-${index}`, { "background-color": `${colors.grey25}` })
        .reversed(true)

        .to(
          `#tabText2-${index}`,
          { color: `${colors.primaryPurple}`, "text-decoration": "underline" },
          "<50%"
        )
        .reversed(true)

        .to(
          `#chevron2-${index}`,
          { rotate: 180, fill: `${colors.primaryPurple}` },
          "<10%"
        )
        .reversed(true)

        .to(`#subTabContainer2-${index}`, { height: "auto" }, "<10%")
        .reversed(true)

        .to(`#subTab2-${index}`, { stagger: 0.15 }, ">")
        .reversed(true)

      tab.addEventListener("click", expandSubTabs)
    })

    const subTabs = gsap.utils.toArray(".subTabs2")

    subTabs.forEach((subTab, index) => {
      const tl2 = gsap.timeline()
      subTab.animation = tl2
        .to(`#subTab2-${index}`, { "background-color": `${colors.grey25}` })
        .reversed(true)

        .to(
          subTabTextRef2.current,
          { color: `${colors.primaryPurple}` },
          "<50%"
        )
        .reversed(true)

        .to(
          `#subChevron2-${index}`,
          { rotate: 180, fill: `${colors.primaryPurple}` },
          "<10%"
        )
        .reversed(true)

        .to(`#InnerSubTabContainer2-${index}`, { height: "auto" }, "<10%")
        .reversed(true)

        .to(`#InnerSubTab2-${index}`, { stagger: 0.15 }, ">")
        .reversed(true)

      subTab.addEventListener("click", expandInnerSubTabs)
    })

    function expandSubTabs() {
      this.animation.reversed(!this.animation.reversed())
    }

    function expandInnerSubTabs() {
      this.animation.reversed(!this.animation.reversed())
    }

    const burgerBarTL = gsap.timeline({ paused: true })
    burgerBarTL
      .to(
        "#slice1-2",
        { rotate: 40, y: getMedia(0, 0, vwToPx(1.2), vwToPx(4.2)) },
        "start"
      )
      .to("#slice2-2", { rotate: 40, opacity: 0 }, "start")
      .to(
        "#slice3-2",
        { rotate: -40, y: getMedia(0, 0, vwToPx(-1.586), vwToPx(-2.5)) },
        "start"
      )

    function expandMenu() {
      if (hamburgerRef.current === true) {
        gsap.to("#allTabs2", { height: "auto" })
        burgerBarTL.play()
        hamburgerRef.current = false
      } else {
        gsap.to("#allTabs2", { height: 0 })
        burgerBarTL.reverse()
        hamburgerRef.current = true
      }
    }
  }, [hamburgerRef.current])

  const tabContent = [
    {
      name: "Product",
      subTabs: [
        {
          name: "Serverless Printing",
          link: "/",
          innerSubTabs: [
            {
              name: "Serverless Printing Infrastructure",
              url: "/serverless-printing-infrastructure/",
            },
            {
              name: "Mobile Print",
              url: "/mobile-printing-information/",
            },
            {
              name: "Self-Service Printer Installation ",
              url: "/self-service-printer-installation-portal/",
            },
            //Automated Deployment Will go here
            /* ---------------------------------------------*/
            {
              name: "Supported Printers",
              url: "/cpa/",
            },
            {
              name: "Reporting",
              url: "/advanced-reporting-information/",
            },
          ],
        },
        {
          name: "Security",
          link: "/",
          innerSubTabs: [
            {
              name: "Zero Trust",
              url: "/zero-trust/",
            },
            {
              name: "Print Nightmare",
              url: "/print-nightmare/",
            },
            {
              name: "Secure Release Printing",
              url: "/secure-release-printing-information/",
            },
            {
              name: "Off-Network Printing",
              url: "/off-network-printing/",
            },
            {
              name: "Mobile Printing",
              url: "/mobile-printing-information/",
            },
            {
              name: "Secure Print Management",
              url: "/secure-print-management/",
            },
          ],
        },
        {
          name: "Output Automation",
          link: "/",
          innerSubTabs: [
            {
              name: "Simplified Scanning",
              url: "/simplified-scanning/",
            },
            {
              name: "Output Management",
              url: "/output-management",
            },
          ],
        },
        {
          name: "Reporting & Control",
          link: "/",
          innerSubTabs: [
            {
              name: "Print Quota Management",
              url: "/print-quota-management/",
            },
            {
              name: "Advanced Reporting",
              url: "/advanced-reporting-information/",
            },
          ],
        },
        {
          name: "Integrations",
          link: "/",
          innerSubTabs: [
            {
              name: "PrinterLogic for IGEL",
              url: "/igel-integration/",
            },
            {
              name: "PrinterLogic for IdP",
              url: "/idp/",
            },
            {
              name: "PrinterLogic for VDI",
              url: "/vdi-printing-information/",
            },
            {
              name: "PrinterLogic for Chromebook",
              url: "/chromebook-printing/",
            },
            {
              name: "PrinterLogic for EMR",
              url: "/emr-printing-information/",
            },
          ],
        },
        {
          name: "Vasion Platform",
          link: "https://vasion.com/",
          innerSubTabs: [],
        },
      ],
    },
    {
      name: "Industry",
      subTabs: [
        {
          name: "Government",
          link: "/government/",
        },
        {
          name: "Healthcare",
          link: "/healthcare/",
        },
        {
          name: "Education",
          link: "/education/",
        },
        {
          name: "Enterprise",
          link: "/enterprise-print-management/",
        },
        {
          name: "Manufacturing",
          link: "/manufacturing/",
        },
      ],
    },
    {
      name: "Resources",
      subTabs: [
        {
          name: "Blog",
          link: "/blog",
        },
        {
          name: "All Resources",
          link: "/resources/",
        },
        {
          name: "Security Bulletins",
          link: "https://help.printerlogic.com/saas/1-Printerlogic/Release_Notes/Security_Bulletin.htm",
        },
      ],
    },
  ]

  const tabs = tabContent.map((tab, index) => (
    <AllTabsMapWrapper key={tab.name}>
      <Tab id={`tab2-${index}`} className="tabs2">
        <TabText id={`tabText2-${index}`}>{tab.name}</TabText>
        {tab.subTabs && <DownArrow id={`chevron2-${index}`} />}
      </Tab>
      <SubTabContainer id={`subTabContainer2-${index}`}>
        {tab.subTabs?.map((subTab, i) => (
          <AllTabsMapWrapper key={subTab.name}>
            <SubTab className="subTabs2" id={`subTab2-${index}`}>
              {subTab.innerSubTabs ? (
                <Link>
                  <SubTabText ref={subTabTextRef2} id={`subTabText2-${i}`}>
                    {subTab.name}
                    <SubDownArrow id={`subChevron2-${i}`} />
                  </SubTabText>
                </Link>
              ) : (
                <Link href={subTab.link}>
                  <SubTabText ref={subTabTextRef2} id={`subTabText2-${i}`}>
                    {subTab.name}
                  </SubTabText>
                </Link>
              )}
            </SubTab>
            <InnerSubTabContainer
              className="InnerSubContainers2"
              id={`InnerSubTabContainer2-${i}`}
            >
              {subTab.innerSubTabs?.map((innerSubTab, innerIndex) => (
                <Link key={innerSubTab.url} href={innerSubTab.url}>
                  <InnerSubTab
                    className="InnerSubTabs2"
                    id={`InnerSubTab2-${i}`}
                  >
                    <InnerSubTabText id={`InnerSubTabText22-${innerIndex}`}>
                      {innerSubTab.name}
                    </InnerSubTabText>
                  </InnerSubTab>
                </Link>
              ))}
            </InnerSubTabContainer>
          </AllTabsMapWrapper>
        ))}
      </SubTabContainer>
    </AllTabsMapWrapper>
  ))

  return (
    <StickyWrapper>
      <Wrapper ref={ref => setNavWrapper(ref)}>
        <Logo alt="logo" src={logoPL} />
        <HamburgerContainer id="hamburger2">
          <HamburgerSlice1 id="slice1-2" />
          <HamburgerSlice2 id="slice2-2" />
          <HamburgerSlice3 id="slice3-2" />
        </HamburgerContainer>
      </Wrapper>
      <AllTabs id="allTabs2">
        {tabs}
        <DemoTab>
          <GlobalButton outline demo>
            SCHEDULE A DEMO
          </GlobalButton>
        </DemoTab>
      </AllTabs>
    </StickyWrapper>
  )
}

export default SecondaryNavResp

const AllTabsMapWrapper = styled.div``

const Link = styled.a`
  color: unset;
`
const InnerSubTabText = styled.p`
  ${text.m1};
`
const InnerSubTab = styled.div`
  ${media.tablet} {
    padding: 2.344vw 0 2.344vw 13.281vw;
  }

  ${media.mobile} {
    padding: 3.271vw 0 3.271vw 21.963vw;
  }
`
const InnerSubTabContainer = styled.div`
  background-color: ${colors.grey25};
  overflow: hidden;
  height: 0;
`
const SubTabText = styled.p`
  ${text.m1};
`
const SubTab = styled.div`
  ${media.tablet} {
    padding: 2.344vw 0 2.344vw 9.57vw;
  }

  ${media.mobile} {
    padding: 3.271vw 0 3.271vw 15.421vw;
  }
`
const SubTabContainer = styled.div`
  background-color: ${colors.grey25};
  overflow: hidden;
  height: 0;
`
const DownArrow = styled(DownChevron)`
  margin-left: auto;

  ${media.tablet} {
    width: 0.977vw;
    height: 0.977vw;
  }

  ${media.mobile} {
    width: 2.336vw;
    height: 2.336vw;
  }
`

const SubDownArrow = styled(DownArrow)`
  ${media.tablet} {
    margin-left: 0.781vw;
    width: 0.977vw;
    height: 0.977vw;
  }

  ${media.mobile} {
    margin-left: 1.869vw;
    width: 2.336vw;
    height: 2.336vw;
  }
`

const DemoTab = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  background-color: ${colors.grey50};

  ${media.tablet} {
    padding: 1.172vw 4.688vw 3.125vw 0vw;
  }

  ${media.mobile} {
    padding: 3.738vw 6.542vw 6.542vw 0vw;
  }
`
const TabText = styled.p`
  ${text.m1};
`
const Tab = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.grey50};

  ${media.tablet} {
    padding: 2.344vw 5.859vw;
    height: 6.25vw;
    margin: -0.098vw 0vw;
  }

  ${media.mobile} {
    height: 14.953vw;
    padding: 5.607vw 6.542vw;
    margin: -0.234vw 0vw;
  }
`
const AllTabs = styled.div`
  overflow: hidden;
  height: 0;
`
const HamburgerSlice3 = styled.div`
  background-color: black;

  ${media.tablet} {
    width: 2.93vw;
    height: 2px;
  }

  ${media.mobile} {
    height: 2px;
    width: 7.009vw;
  }
`
const HamburgerSlice2 = styled.div`
  background-color: black;

  ${media.tablet} {
    width: 2.93vw;
    height: 2px;
  }

  ${media.mobile} {
    height: 2px;
    width: 7.009vw;
  }
`
const HamburgerSlice1 = styled.div`
  background-color: black;

  ${media.tablet} {
    width: 2.93vw;
    height: 2px;
  }

  ${media.mobile} {
    height: 2px;
    width: 7.009vw;
  }
`
const HamburgerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;

  ${media.tablet} {
    gap: 1.172vw;
  }

  ${media.mobile} {
    gap: 2.804vw;
  }
`
const Logo = styled.img`
  ${media.tablet} {
    height: 1.953vw;
    width: 12.988vw;
  }

  ${media.mobile} {
    height: 4.673vw;
    width: 31.075vw;
  }
`
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 1000;
  background-color: ${colors.grey25};
  filter: drop-shadow(0vw 0vw 0.833vw rgba(0, 0, 0, 0.2));

  ${media.tablet} {
    padding: 0vw 3.906vw;
    height: 8.789vw;
    filter: drop-shadow(0vw 0vw 1.172vw rgba(0, 0, 0, 0.2));
  }

  ${media.mobile} {
    padding: 0vw 6.075vw;
    height: 21.028vw;
    filter: drop-shadow(0vw 0vw 2.804vw rgba(0, 0, 0, 0.2));
  }
`

const StickyWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000;
  filter: drop-shadow(0vw 0vw 0.833vw rgba(0, 0, 0, 0.2));

  ${media.tablet} {
    filter: drop-shadow(0vw 0vw 1.172vw rgba(0, 0, 0, 0.2));
  }

  ${media.mobile} {
    filter: drop-shadow(0vw 0vw 2.804vw rgba(0, 0, 0, 0.2));
  }
`
