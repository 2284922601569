import React, {useEffect, useRef, useState} from 'react';

import gsap from 'gsap'
import styled from 'styled-components';
import media from 'styles/media';
import colors from 'styles/colors';
import text from 'styles/text';
import PLbyVasionTop from 'images/Nav/PLbyVasionTop.svg'
import {ReactComponent as DownChevron} from '/src/images/ChevronDown.svg'
import getMedia from '../../utils/getMedia';
import {vwToPx} from 'utils/functions'
import GlobalButton from 'components/buttons/GlobalButton';

const PrimeNavResp = ({slug}) => {
  const nonHomeSlug = slug === '' ? '' : slug
  const hamburgerRef = useRef(true);
  const subTabTextRef = useRef(true);
  const [navWrapper, setNavWrapper] = useState(null);

  useEffect(() => {
    document.querySelector('#hamburger').addEventListener('click', expandMenu)

    const tabs = gsap.utils.toArray(".tabs");

    tabs.forEach((tab, index) => {
      const tl = gsap.timeline()
      tab.animation =
        tl.to(`#tab-${index}`, 
        {'background-color': `${colors.grey25}`}).reversed(true)

        .to(`#tabText-${index}`, 
        {color: `${colors.primaryPurple}`,  'text-decoration': 'underline'}, '<50%').reversed(true)

        .to(`#chevron-${index}`, 
        { rotate: 180, fill: `${colors.primaryPurple}` }, '<10%').reversed(true)

        .to(`#subTabContainer-${index}`, 
        { height: 'auto'}, '<10%').reversed(true)

        .to(`#subTab-${index}`, 
        {stagger: .15}, '>').reversed(true)
        

      tab.addEventListener("click", expandSubTabs);
    });
    
    const subTabs = gsap.utils.toArray(".subTabs");

    subTabs.forEach((subTab, index) => {
      const tl2 = gsap.timeline()
      subTab.animation =
      tl2.to(`#subTab-${index}`, 
      {'background-color': `${colors.grey25}`}).reversed(true)
      
      .to(subTabTextRef.current, 
      {color: `${colors.primaryPurple}`}, '<50%').reversed(true)
      
      .to(`#subChevron-${index}`, 
      { rotate: 180, fill: `${colors.primaryPurple}` }, '<10%').reversed(true)
      
      .to(`#InnerSubTabContainer-${index}`, 
      { height: 'auto'}, '<10%').reversed(true)
      
      .to(`#InnerSubTab-${index}`, 
      {stagger: .15}, '>').reversed(true)
      
      subTab.addEventListener("click", expandInnerSubTabs);
    });

    
    function expandSubTabs() {
      this.animation.reversed(!this.animation.reversed());
    }
    
    function expandInnerSubTabs() {
      this.animation.reversed(!this.animation.reversed());
    }

    const burgerBarTL = gsap.timeline({paused: true});
    burgerBarTL.to('#slice1', {rotate: 40, y: getMedia(0,0,vwToPx(1.2),vwToPx(4.2))}, 'start')
    .to('#slice2', {rotate: 40, opacity: 0}, 'start')
    .to('#slice3', {rotate: -40, y: getMedia(0,0,vwToPx(-1.586),vwToPx(-2.5))}, 'start')

    function expandMenu() {
      if (hamburgerRef.current === true) {
        gsap.to('#allTabs', {height: 'auto'})
        burgerBarTL.play()
        hamburgerRef.current = false
      } else {
        gsap.to('#allTabs', {height: 0})
        burgerBarTL.reverse()
        hamburgerRef.current = true
      }
    }
  }, [hamburgerRef.current])
  
  const tabContent = [
    {
      name: 'Company',
      subTabs: [
        {
          name: 'About Us',
          link: 'https://vasion.com/company/'
        },
        {
          name: 'Careers',
          link: 'https://vasion.com/careers/'
        },
        {
          name: 'Contact Us',
          link: '/contact-us'
        },
        {
          name:'Giving',
          link:'https://vasion.com/vasion-gives-back'
        },
        {
          name:'Our Customers',
          link:'https://printerlogic.com/customers/'
        }, 
      ]
    },
     {
      name: 'Partners',
      subTabs: [
        {
          name: 'Partner Overview',
          link: 'https://vasion.com/partner-overview/'
        },
        {
          name: 'MSP Partners',
          link: 'https://vasion.com/msp-partner/'
        },
        {
          name: 'Sales Partners',
          link: 'https://vasion.com/sales-partners/'
        },
        {
          name: 'Ecosystem Partners',
          link: 'https://vasion.com/ecosystem-partners/'
        },
        {
          name: 'Become a Partner',
          link: 'https://partners.vasion.com/prm/English/s/applicant'
        },
        {
          name: 'Partner Login',
          link: 'https://partners.vasion.com'
        },
      ]
    },
    {
      name: 'Support',
      subTabs: [
        {
          name: 'Supported Printers',
          link: '/cpa'
        },
        {
          name: 'Support',
          link: 'https://kb.printerlogic.com/s/contact-printerlogic-support'
        },
      ]
    },
    {
      name: 'Language',
      subTabs: [
        {
          name: 'English',
          link: `https://printerlogic.com/${nonHomeSlug}`
        },
        {
          name: 'German',
          link: `https://de.printerlogic.com/${nonHomeSlug}`
        },
        {
          name: 'French',
          link: `https://fr.printerlogic.com/${nonHomeSlug}`
        }
      ]
    },
  ]

  const tabs = tabContent.map((tab, index) => 
  <AllTabsMapWrapper key={tab.name}>
  <Tab id={`tab-${index}`} className='tabs'>
    <TabText id={`tabText-${index}`}>
      {tab.name}
    </TabText>
    {tab.subTabs && <DownArrow id={`chevron-${index}`}/>}
  </Tab>
  <SubTabContainer id={`subTabContainer-${index}`}>
    {tab.subTabs?.map((subTab, i) => 
    <AllTabsMapWrapper key={subTab.name}>
      <SubTab className='subTabs' id={`subTab-${index}`}>
      {subTab.innerSubTabs ? <Link>
          <SubTabText ref={subTabTextRef} id={`subTabText-${i}`}>
            {subTab.name}
          <SubDownArrow id={`subChevron-${i}`}/>
          </SubTabText>
        </Link> 
        :
        <Link href={subTab.link}>
          <SubTabText ref={subTabTextRef} id={`subTabText-${i}`}>
            {subTab.name}
          </SubTabText>
        </Link>}
      </SubTab>
      <InnerSubTabContainer className='InnerSubContainers' id={`InnerSubTabContainer-${i}`}>
        {subTab.innerSubTabs?.map((innerSubTab, innerIndex) =>
          <InnerSubTab key={innerSubTab.name} className='InnerSubTabs' id={`InnerSubTab-${i}`}>
            <Link href={innerSubTab.link}>
              <InnerSubTabText id={`InnerSubTabText22-${innerIndex}`}>
                {innerSubTab.name}
              </InnerSubTabText>
            </Link>
        </InnerSubTab>
        )}
      </InnerSubTabContainer>
      </AllTabsMapWrapper>
    )}
  </SubTabContainer>
  </AllTabsMapWrapper>
  )

  return (
    <>
    <Wrapper ref={ref => setNavWrapper(ref)}>
      <Link href='https://vasion.com'>
        <Logo alt="logo" src={PLbyVasionTop}/>
      </Link>
      <HamburgerContainer id='hamburger'>
        <HamburgerSlice1 id='slice1'/>
        <HamburgerSlice2 id='slice2'/>
        <HamburgerSlice3 id='slice3'/>
      </HamburgerContainer>
    </Wrapper>
    <AllTabs id='allTabs'>
     {tabs}
     <DemoTab>
      <GlobalButton demo outline>
        SCHEDULE A DEMO
      </GlobalButton>
     </DemoTab>
    </AllTabs>
    </>
  )
}

export default PrimeNavResp

const AllTabsMapWrapper = styled.div``
const Link = styled.a`
  color: unset;
`
const InnerSubTabText = styled.p`
  ${text.m1};
`
const InnerSubTab = styled.div`
  ${media.tablet} {
    padding: 2.344vw 0 2.344vw 13.281vw;
  }

  ${media.mobile} {
    padding: 3.271vw 0 3.271vw 21.963vw;
  }
`
const InnerSubTabContainer = styled.div`
  background-color: ${colors.grey25};
  overflow: hidden;
  height: 0;
`
const SubTabText = styled.p`
  ${text.m1};
`
const SubTab = styled.div`

  ${media.tablet} {
    padding: 2.344vw 0 2.344vw 9.57vw;
  }

  ${media.mobile} {
    padding: 3.271vw 0 3.271vw 15.421vw;
  }
`
const SubTabContainer = styled.div`
  background-color: ${colors.grey25};
  overflow: hidden;
  height: 0;
`
const DownArrow = styled(DownChevron)`
  margin-left: auto;

  ${media.tablet} {
    width: 0.977vw;
    height: 0.977vw;
  }
  
  ${media.mobile} {
    width: 2.336vw;
    height: 2.336vw;
  }
`

const SubDownArrow = styled(DownArrow)`
  ${media.tablet} {
    margin-left: 0.781vw;
    width: 0.977vw;
    height: 0.977vw;
  }

  ${media.mobile} {
    margin-left: 1.869vw;
    width: 2.336vw;
    height: 2.336vw;
  }
`

const DemoTab = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  background-color: ${colors.grey50};

  ${media.tablet} {
    padding: 1.172vw 4.688vw 3.125vw 0vw;
  }
  
  ${media.mobile} {
    padding: 3.738vw 6.542vw 6.542vw 0vw;
  }
`
const TabText = styled.p`
  ${text.m1};
`
const Tab = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.grey50};

  ${media.tablet} { 
    padding: 2.344vw 5.859vw;
    height: 6.25vw;
  }
  
  ${media.mobile} {
    height: 14.953vw;
    padding: 5.607vw 6.542vw;
  }
`
const AllTabs = styled.div`
  overflow: hidden;
  height: 0;
`
const HamburgerSlice3 = styled.div`
    background-color: ${colors.grey75};

  ${media.tablet} {
    width: 2.93vw;
    height: 2px;
  }

  ${media.mobile} {
    height: 2px;
    width: 7.009vw;
  }
`
const HamburgerSlice2 = styled.div`
    background-color: ${colors.grey75};

  ${media.tablet} {
    width: 2.93vw;
    height: 2px;
  }

  ${media.mobile} {
    height: 2px;
    width: 7.009vw;
  }
`
const HamburgerSlice1 = styled.div`
  background-color: ${colors.grey75};

  ${media.tablet} {
    width: 2.93vw;
    height: 2px;
  }

  ${media.mobile} {
    height: 2px;
    width: 7.009vw;
  }
`
const HamburgerContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: auto;

  ${media.tablet} {
    gap: 1.172vw;
  }

  ${media.mobile} {
    gap: 2.804vw;
  }
`
const Logo = styled.img`
  ${media.tablet} {
    height: 1.953vw;
    width: 12.988vw;
  }

  ${media.mobile} {
    height: 4.673vw;
    width: 31.075vw;
  }
`
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 1000;
  background-color: ${colors.grey700};
  
  ${media.tablet} {
    padding: 0vw 3.906vw;
    height: 4.883vw;
  }

  ${media.mobile} {
    padding: 0vw 6.075vw;
    height: 11.682vw;
  }
`